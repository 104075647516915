import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';



function App() {
  return (
    <div className="App-container">
      <div className="App">
        <header className="App-header">
          {/* <div className="App-content"> */}
          <div className="overlay"></div>
          <div className="logo">
            <img src={logo}  className="App-logo" alt="logo" />
          </div>
          <div className="copy">TRY ON FASHION<br/>WITH YOUR AVATAR<br/><br/>
          <a href="https://bkhp0qentx1.typeform.com/to/mVYz6UTg">JOIN WAITLIST</a>
          </div>
            <div className="background-container">
          <div className="background-image"></div>
          <div className="background-image"></div>
          <div className="background-image"></div>
        </div>
        </header>
      </div>
    </div>
  );
}

export default App;
